import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import { config, paths } from './common/constant';
// import AuthProvider from './service/auth-provider/authProvider';
// import { history } from './jwt/_helpers';
import i18next from 'i18next'	
import { initReactI18next } from 'react-i18next'	
import HttpApi from 'i18next-http-backend'	
import LanguageDetector from 'i18next-browser-languagedetector'
// Redux Required Files
import { Provider } from 'react-redux'
import { configureStore } from './redux/configureStore';
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import * as Sentry from "@sentry/react";
import { config } from './common/constant';

const { store , persistor } = configureStore();
const history = createBrowserHistory();

const options = {
  order: ['path', 'cookie', 'htmlTag'],
  caches: ['cookie'],
}

const autoDetectOption = {
// order and from where user language should be detected
order: ['navigator', 'htmlTag', 'path', 'subdomain'],

// keys or params to lookup language from
lookupQuerystring: 'lng',
lookupCookie: 'i18next',
lookupLocalStorage: 'i18nextLng',
lookupSessionStorage: 'i18nextLng',
lookupFromPathIndex: 0,
lookupFromSubdomainIndex: 0,

// cache user language on
caches: ['localStorage', 'cookie'],
excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

// optional expire and domain for set cookie
cookieMinutes: 10,
cookieDomain: 'myDomain',

// optional htmlTag with lang attribute, the default is:
htmlTag: document.documentElement,

// optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
cookieOptions: { path: '/', sameSite: 'strict' }
}

i18next
.use(HttpApi)
.use(LanguageDetector)
.use(initReactI18next)
.init({
  supportedLngs: ['en', 'ms'],
  fallbackLng: 'en',
  debug: false,
  // Options for language detector
  detection: localStorage.getItem('isSelectedLanguage') === null ? autoDetectOption : options,
  backend: {
    loadPath: '/assets/locales/{{lng}}/translation.json',
  },
})

export const ENVIRONMENT = (() => {
  if (config.TYPE === 'DEV') {
    return "development";
  } else if (config.TYPE === 'UAT') {
    return "uat";
  } else {
    return "production";
  }
  })();

  // list specifies which URLs should have tracing enabled
  const traceTargets = [
    "localhost",
    "https://dev-ilham.givepls.com/",
    "https://uat-donate.ilham.sg/",
    "https://donate.ilham.sg/",
    new RegExp(`^${config.BASE_URL_DOMAIN.replace(/\./g, "\\.")}`), // Escapes dots for RegExp
    new RegExp(`^${config.BASE_URL.replace(/\./g, "\\.")}`),
    new RegExp(`^${config.NGS_SINGPASS_URL.replace(/\./g, "\\.")}`)
  ];

  Sentry.init({
    dsn: "https://74aa8aa061c61b9546b4752b734cb851@o4508166161825792.ingest.us.sentry.io/4508674115895296",
    environment: ENVIRONMENT,
    integrations: [
      Sentry.browserTracingIntegration(history),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
      Sentry.reactRouterV5BrowserTracingIntegration({ history })
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: traceTargets,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    beforeSend(event) {
      if (event.exception) {
        // Prepend the hostname to the error title/message
        const hostname = window.location.hostname;
        if (event.exception.values && event.exception.values.length > 0) {
          event.exception.values[0].value = `[${hostname}] ${event.exception.values[0].value}`;
        }
        event.tags = {
          ...event.tags,
          hostname: hostname, // Add hostname as a tag for filtering purposes
        };
      }
      return event;
    },
  });


document.addEventListener('DOMContentLoaded', async () => {
      ReactDOM.render(
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </PersistGate>
            </Provider>,
      document.getElementById('root')
      );
});
