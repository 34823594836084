import { handleResponse, authHeader } from '../_helpers';
import { config } from "../../common/constant";
import { authContext } from '../../data/authContext';
import { captureApiError } from '../../common';

export const profile = {
    getUserData,
    getCardData,
    getCardDetails,
    transactionListing,
    getDonorTransactions,
    checkNRICUnique
};



async function checkNRICUnique(payload) {
  
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };

    const url = config.BASE_URL_DOMAIN + `/donor-v8/api/User/checkCognitoNricExists`; 
    
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(result => {
            
            if (result.data !== null && result.data !== undefined && result.data.status === 1) {
                return result;
            } else {
                captureApiError(result.data)
                return false;
            }
            
        }).catch(error => {
           
            return [];
        });
}

async function getUserData(userDetails) {
  
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(userDetails)
        };
        const url = config.BASE_URL + `/User/getSignInUserDetails`; 
        return fetch(url, requestOptions)
            .then(handleResponse)
            .then(result => {
                
                 if (result.data !== null && result.data !== undefined && result.data.status === 1) {
                    return result;
                }
                else {
                    captureApiError(result.data)
                }
            }).catch(error => {
               
                return [];
            });
    }

async function getCardData(guid) {
    
    const requestOptionsForStripe = {
        method: 'GET',
        headers: authHeader(),
    };
    const url = config.BASE_URL + `/User/getUserStripeToken?Guid=` + guid +'&charityId='+ config.NGS_CHARITY_ID; 
    
    return fetch(url, requestOptionsForStripe)
        .then(handleResponse)
        .then(result => {
            
            if (result.data.userStripeTokens.result !== null && result.data.userStripeTokens.result !== undefined) {
                let resultData = result.data.userStripeTokens.result;
                return resultData;
            }
            else {
                captureApiError(result.data)
            }
        }).catch(error => {
            return [];
        });
}

async function getCardDetails(cardId, customerId) {

    const requestOptionsForStripe = {
        method: 'GET',
        headers: { 'Authorization': 'Bearer ' + config.STRIPE_KEY },
    };

    return fetch('https://api.stripe.com/v1/customers/' + customerId + '/sources/' + cardId, requestOptionsForStripe)
        .then(handleResponse)
        .then(result => {
            
            return result;
        }).catch(error => {
            return [];
        });

}


async function transactionListing(transactionData) {
    
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(transactionData)
    };
    
    const url = config.BASE_URL + `/v1/Donation/getUserTransactions`; 

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(result => {
            
             if (result.data !== null && result.data !== undefined && result.data.status === 1) {
                return result;
            }
            else {
                captureApiError(result.data)
            }
        }).catch(error => {
           
            return [];
        });
}


async function getDonorTransactions({pageNumber = 0, pageSize = 0, uuid = "",phoneNumber, email}) {
    const URL = config.BASE_URL_DOMAIN + '/payment-v8/api/Donation/getDonorTransactions';

    const data = {
        phoneNumber,
        pageNo: pageNumber,
        pageSize,
        email,
    }

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    requestOptions.headers['uuid'] = uuid;

    try {
        const res = await fetch(URL, requestOptions);
        const result = await handleResponse(res);

        return {
            message: result?.data?.message,
            resultDonationListResponse: 
                [...(result?.data?.resultDonationListResponse ?? [])].map((donation) => (
                    {
                        "transactionNo": donation?.transactionNo,
                        "donorName": donation?.donorName,
                        "donorPhone": donation?.donorPhone,
                        "donorEmail": donation?.donorEmail,
                        "nricNumber": donation.nricNumber,
                        "donationAmount": donation?.donationAmount,
                        "donationFrom": donation?.donationFrom,
                        "donationTimeStamp": donation?.donationTimeStamp?.split(' ')?.join(' '),
                        "method": donation?.method,
                        "status": donation?.status,
                        "paymentMethod": donation?.paymentMethod,
                        "cardLastDigit": donation?.cardLastDigit,
                        "nextDonationDate": donation?.nextDonationDate?.split(' ')?.join(' '),
                        "receiptLink": donation?.receiptLink,
                        "isSingPass": donation?.isSingPass,
                        "receiptNo": donation?.receiptNo,
                        "donationTo": donation?.donationTo,
                        "recurringFrequency": donation?.recurringFrequency,
                        "donationType": donation?.donationType,
                        "paymentType": donation?.paymentType,
                        "source": donation?.source,
                    }
                )),
            status: result?.data?.status ?? 0,
            totalRecords: result?.data?.totalRecords ?? 0
        };
    } catch (err) {
        return { status: 0, message: err.message };
    }
}