import { handleResponse, authHeader } from '../_helpers';
import { config, GOOGLE_API_KEY_AUTOCOMPLETE } from "../../common/constant";
import { captureApiError } from '../../common';

export const EnrollService = {
    enrollDonorManuallyPreDonation,
    getPostalCodeFromGoogleAPI,
    confirmSignpassDetails,
    getUserDataFromNRIC,
    checkMobileNoExists,
    registerCognitoUser,
    updateCognitoUser,
    getUserData,
    getPostalCode
};

async function getUserDataFromNRIC(nric) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    requestOptions.headers['uuid'] = nric;

    const url = config.BASE_URL_DOMAIN + `/donor-v8/api/User/getLastTxnDonorDetailsByNric`;
    
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(result => {
            if (result.data !== null && result.data !== undefined && result.data.status === 1) {
                return result.data;
            } else {
                captureApiError(result.data)
                return result.data
            }
        }).catch(error => {
            return [];
        });
}

async function checkMobileNoExists(payload, nric) {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: authHeader()
    };

    requestOptions.headers['uuid'] = nric;

    const url = config.BASE_URL_DOMAIN + `/donor-v8/api/User/checkCognitoMobileExists`;
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(result => {
            if (result.data !== null && result.data !== undefined && result.data.status === 1) {
                return result.data;
            } else {
                captureApiError(result.data)
                return result.data
            }
        }).catch(error => {
            return [];
        });
}

async function enrollDonorManuallyPreDonation(payload) {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: authHeader()
    };

    const url = config.BASE_URL_DOMAIN + `/donor-v8/api/User/enrollDonorManuallyPreDonation`;
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(result => {
            if (result.data !== null && result.data !== undefined && result.data.status === 1) {
                return result.data;
            } else {
                captureApiError(result.data)
                return result.data
            }
        }).catch(error => {
            return [];
        });
}

async function confirmSignpassDetails(confirmSingpassPayload) {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(confirmSingpassPayload),
        headers: authHeader()
    };

    const url = config.BASE_URL_DOMAIN + "/donor-v8/api/User/confirmSingpassPreDonation";
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(result => {
            if (result.data !== null && result.data !== undefined && result.data.status === 1) {
                return result.data;
            } else {
                captureApiError(result.data)
                return result.data
            }
        }).catch(error => {
            return { status: 0 };
        });
}


async function getUserData(data) {
    let headers = authHeader()
    headers['Content-Type'] = 'application/json'

    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data)
    };
    const url = config.BASE_URL_DOMAIN + `/donor-v8/api/User/getDonorByTransactionNumber`;
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(result => {
            if (result.data !== null && result.data !== undefined && result.data.status === 1) {
                return result.data;
            } else {
                captureApiError(result.data)
                return result.data
            }
        }).catch(error => {
            return { status: 0 };
        });
}

async function getPostalCodeFromGoogleAPI(lat, lan) { 
    const requestOptions = { 
        method: 'GET', 
    }; 

    const url = config.GOOGLE_POSTAL_CODE_BASE_URL + `/api/geocode/json?latlng=${lat},${lan}&key=${GOOGLE_API_KEY_AUTOCOMPLETE}`;

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(result => {
            if (result.status == 'OK')
                return result.results[0];
            else
                return { status: 0 };
        }).catch(error => {
            return { status: 0 };
        });
}

async function registerCognitoUser({ uniqueUserId, phoneCountryCode, phoneNumber}) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ uniqueUserId, phoneCountryCode, phoneNumber})
    };

    const URL = config.BASE_URL_DOMAIN + `/donor-v8/api/User/registerCognitoUser`;

    requestOptions.headers['uuid'] = uniqueUserId;

    try {
        const res = await fetch(URL, requestOptions);
        const result = await handleResponse(res) 
        if (result.data && result.data.status) {
            return result.data;
        } else {
            captureApiError(result.data)
            return result.data
        }
    } catch (err) {
        return { status: 0, message: err.message };
    }
}

async function updateCognitoUser({ uniqueUserId, phoneCountryCode, phoneNumber}) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ phoneCountryCode, phoneNumber})
    };

    const URL = config.BASE_URL_DOMAIN + `/donor-v8/api/User/updateCognitoUser`;

    requestOptions.headers['uuid'] = uniqueUserId;

    try {
        const res = await fetch(URL, requestOptions);
        const result = await handleResponse(res)

        if (result.data && result.data.status) {
            return result.data;
        } else {
            captureApiError(result.data)
            return result.data
        }
    } catch (err) {
        return { status: 0, message: err.message };
    }
}

async function getPostalCode(payload) {
    if(!payload) return;
    const requestOptions = {
      method: "GET",
      // headers: authHeader()
    };
    const url = config.BASE_URL_POSTALCODE + `/payers/api/Payer/getPostalAdress?searchPostalCode=${payload}`

    return fetch(url, requestOptions)
      .then(handleResponse)
      .then((result) => {
        if (
          result !== null &&
          result !== undefined &&
          result.status === 1
        ) {
          return result;
        } else {
          return false;
        }
      })
      .catch((error) => {
        return false;
      });
}